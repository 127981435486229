
<kng-news [config]="config"></kng-news>

<div class="mdc-drawer"  drawer="modal" #navigation 
    [class.mdc-drawer--modal]="navigation.open"
    [class.mdc-drawer--open]="navigation.open" (click)="navigation.open = !navigation.open"> 
  <!-- <img class="logo" [src]="hubImage" [routerLink]="['/']" /> -->
  <div class="mdc-drawer__header" [routerLink]="['/store',store,'home']">
    <h3 class="mdc-drawer__title" [innerHtml]="getTagline('t')"></h3>
  </div>
  <div mdc-drawer-content>

    <ul class="mdc-list">
      <li [hidden]="!user.isAuthenticated()" class="">
        <a [routerLink]="['/store',store,'home','me','orders']">{{label.nav_account}}</a>
      </li>
      <li [hidden]="user.isAuthenticated()">
        <a [routerLink]="['/store',store,'home','me','login']">{{label.nav_login}}</a>
      </li>

      <li>
        {{label.nav_i18n}}
        <input type="radio" class="radio-primary align-right" value="en"  name="lang" id="lang-en" [checked]="$i18n.locale=='en'" (click)="onLang($event,'en')">
        <label for="lang-en">En</label>
        <input type="radio" class="radio-primary" value="fr" name="lang" id="lang-fr" [checked]="$i18n.locale=='fr'" (click)="onLang($event,'fr')">
        <label for="lang-en">Fr</label>
      </li>

      <li *ngFor="let elem of getMenuItems('links')">
        <a [routerLink]="['/store',store,elem.url]" [innerHtml]="elem.name[locale]"></a>
      </li>
    </ul>
    <!-- ADMIN -->
    <ul class="mdc-list" [hidden]="!user.isAdmin()">
      <h3 mdcListGroupSubheader>Administration/Config</h3>
      <li [id]="20+i" class="admin-item " *ngFor="let menu of $navigation.getMenuItems('admin');let i=index;">
        <a [routerLink]="getRouterLink(menu.url)">{{menu.name[locale]}}</a>        
      </li>

    </ul>
  </div>
</div>

<!-- MAIN LAYOUT -->
<div [hidden]="!isAppReady()" class="navbar">

  <!-- [dense]="$navigation.isMobile()" -->
  <mdc-top-app-bar [fixed]="!$navigation.isMobile()">
    <mdc-top-app-bar-row hidden >
      <!-- APPBAR LEFT ACTIONS -->
      <mdc-top-app-bar-section align="start" >
        <!-- <img class="icon hide-sm hide"  [src]="Kimage" (click)="navigation.open = ! navigation.open" [hidden]="isLockedHUB()"/> -->
        <img class="icon icon-hub" [class.icon-show]="isLockedHUB()"  [src]="hubImage" (click)="navigation.open = ! navigation.open" />
        <mdc-top-app-bar-title class="brand" [routerLink]="['/store',store]" [hidden]="isLockedHUB()">
          Karibou.ch      
        </mdc-top-app-bar-title>
      </mdc-top-app-bar-section>


      <!-- LOGIN / REGISTER -->
      <mdc-top-app-bar-section align="end"  class="actions">        
        <a class="label " [routerLink]="['/store',store,'home','me','orders']" [hidden]="!user.isAuthenticated()" mdcTopAppBarNavIcon>
          <span mdcTopAppBarActionItem class="material-symbols-outlined secondary-text" >account_circle</span><!-- <div >{{label.nav_account}}</div>-->
          <!-- DISPLAY MULTI USER -->
          <!-- <span class="cart-users-count">2</span> -->
        </a>
        <a class="label" [routerLink]="['/store',store,'home','me','login']" [hidden]="user.isAuthenticated()" mdcTopAppBarNavIcon>
          <span mdcTopAppBarActionItem class="material-symbols-outlined secondary-text">account_circle</span><!-- <div >{{label.nav_login2}}</div> -->
        </a>
      </mdc-top-app-bar-section>
    </mdc-top-app-bar-row>

    <mdc-top-app-bar-row class="navigation ">      

      <!-- APPBAR-SECTION-START HUB AND DATE -->
      <mdc-top-app-bar-section align="start" class="hide-sm">
        <!-- <img class="icon hide-sm hide"  [src]="Kimage" (click)="navigation.open = ! navigation.open" [hidden]="isLockedHUB()"/> -->

        <div class="mdc-top-app-bar-left" >
          <img class="icon icon-hub" [class.icon-show]="isLockedHUB()"  [src]="hubImage" (click)="navigation.open = ! navigation.open" />
          <span [hidden]="isLockedHUB()" [routerLink]="['/store',store]" class="title">Karibou.ch</span>

        </div>

        <div class="kng-chip" [routerLink]="['/store',store]" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [hidden]="isLockedHUB()"> <i class="material-symbols-outlined start">roofing</i>{{label.nav_bottom_home}}</div>
        <div class="kng-chip" [routerLink]="['/store',store,'home']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{hubTitle}} </div>
        <div class="kng-chip" [routerLink]="['/store',store,'home','shops']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{label.nav_shops}}</div>
            

        <!-- <div class="kng-chip bold" [hidden]="!currentThemeName" (click)="opentheme=!opentheme">
          <i class="material-symbols-outlined start">category</i> {{currentThemeName}} 
        </div> -->


        <div class="kng-chip shopping-cart first"  href="javascript:;" [routerLink]="['/store',store,'home','cart','default']" routerLinkActive="active">
          
          <div > <span class="cart-items-count"></span></div>
          <span  class="end material-symbols-outlined secondary-text" >shopping_cart</span>          
        </div>
        <div class="kng-chip shopping-cart"  href="javascript:;" [routerLink]="['/store',store,'home','cart','default']" routerLinkActive="active" [queryParams]="subscriptionQueryParams" [hidden]="!subsItemsSz">
          
          <div > <span class="subs-items-count"></span></div>
          <span  class="end material-symbols-outlined secondary-text" >more_time</span>          
        </div>

      </mdc-top-app-bar-section>


      <!-- APPBAR-SECTION-MIDDLE -->
      <!-- NOTE: [routerLinkActiveOptions]="{exact:true}" ONLY FOR LINK == '/' -->

      <mdc-top-app-bar-section class="title hide-md hide-lg" >
        <span class="start">{{hubTitle}} </span>
        <div hidden class="kng-chip end"  (click)="opentheme=!opentheme">
          <i class="material-symbols-outlined start">category</i> {{currentThemeName}} <i class="material-symbols-outlined end">keyboard_arrow_down</i>
        </div>
        
      </mdc-top-app-bar-section>

      <!-- APP RIGHT ACTIONS -->

    </mdc-top-app-bar-row>

  </mdc-top-app-bar>

  <!-- CONTENT APP -->
  <div class="mdc-content" #scrollContent>
    <ng-content></ng-content>
  </div>

  <!-- FAB  [exited]="scrollDirection<-5" -->
  <kng-ui-bottom-actions [config]="config" [categories]="categories" [user]="user">  </kng-ui-bottom-actions>

  <!-- ASK LOGIN  -->
  <div class="overlay-pane" [class.overlay-open]="displayLogin">
    <div class="mdc-snackbar install-ios mdc-snackbar--stacked mdc-snackbar--open">
      <div class="mdc-snackbar__surface">
        <div class="close" (click)="displayLogin=false"><i class="material-symbols-outlined">close</i> </div>
        <div class="mdc-snackbar__label install-ios" >
          Pour effectuer une commande nous avons <br/>
          besoin de vérifier votre identité.<br/>
          <b><span class="link" (click)="displayLogin=false" [routerLink]="['/store',store,'home','me','login']">Merci de suivre ce lien </span></b>
        </div>
        <div class="install-ios material-symbols-outlined" >sentiment_very_satisfied</div>
      </div>          
    </div>
  </div>

  <!-- IOS INSTALL -->
  <div class="overlay-pane" [class.overlay-open]="displayIosInstall">
    <div class="mdc-snackbar install-ios mdc-snackbar--stacked mdc-snackbar--open">
      <div class="mdc-snackbar__surface">
        <div class="close" (click)="displayIosInstall=false"><i class="material-symbols-outlined">close</i> </div>
        <div class="mdc-snackbar__label install-ios" [innerHtml]="label.action_install_ios">
          Install this app on your device.<br/>
          Tap the share icon and then<br/>
          <b>Add to homescreen.</b>
        </div>
        
        <div class="install-ios material-symbols-outlined" >arrow_downward</div>
      </div>          
    </div>
  </div>
  <!-- FOOTER -->
  <kng-footer [config]="config"></kng-footer>
</div>